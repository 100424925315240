// lenguaje: jsx
import React, { useState, useEffect } from 'react';
import { Badge, Button, Grid, Loader, Select, Text } from '@mantine/core';
import TableSort from './components/table-sort';
import useTakedataLog from './hooks/useTakedataLog';
import { formatLogDate } from '../../services/Utils';

const taskOptions = [
    { value: '', label: 'Todas' },
    { value: 'bhe_cancel', label: 'Observación BHE' },
    { value: 'bte_issuer', label: 'Resultado Emisión BTE' },
    { value: 'check_match', label: 'Calce de boletas' },
    { value: 'get_contract_b4ind', label: 'Contratos Biin' },
    { value: 'mineduc_task', label: 'Mineduc' },
    { value: 'save_generate_btes', label: 'Emisión BTE' },
];

const headers = [
    { label: 'Tarea', attribute: 'task_name' },
    { label: 'Mensaje', attribute: 'message' },
    { parseMethod: formatLogDate, label: 'Fecha', attribute: 'created_at' },
];

const LogTakedata = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [taskFilter, setTaskFilter] = useState('');
  
    const { paginatedData, logs, isLoading, isValidating, isError, mutate } = useTakedataLog(page, pageSize, search, taskFilter);
  
    useEffect(() => {
      mutate();
    }, [page, pageSize, search, taskFilter]);

  const handlePageChange = (newPage) => setPage(newPage);
  const handlePageSizeChange = (size) => setPageSize(size);
  const handleSearch = (value) => setSearch(value);

  return (
    <>
      { isLoading ? 'Cargando...' : (
        <>
          <Grid my="lg" align="flex-end">
            <Grid.Col span={'auto'}>
              <Text size="34px">Log Takedata</Text>
            </Grid.Col>
            <Grid.Col span={'auto'}>
              <Select
                label="Tarea"
                placeholder="Filtrar por tarea"
                data={taskOptions}
                value={taskFilter}
                onChange={setTaskFilter}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              { isError ? 'Error al cargar los datos' :
                <TableSort
                  paginatedData={paginatedData}
                  headers={headers}
                  data={logs}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  isLoading={isValidating}
                  onSearch={handleSearch}
                />
              }
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};

export default LogTakedata;