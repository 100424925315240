// lenguaje: javascript
import useSWR from 'swr';
import { TakedataLogList } from '../../../services/Api'; // crea o usa una función en Api.js

const useTakedataLog = (page, pageSize, search, taskFilter) => {
    const params = { page, page_size: pageSize, search, task: taskFilter };
    const { data, error, mutate } = useSWR(
      ['takedataLog', page, pageSize, search, taskFilter],
      () => TakedataLogList('superadmin/takedataLog/list', params)
    );
  
  return {
    paginatedData: data || null,
    logs: data || [],
    isLoading: !error && !data,
    isValidating: !error && !data,
    isError: error,
    mutate
  };
};

export default useTakedataLog;