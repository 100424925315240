import React, { Component } from 'react';

export function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

export function validateUsername(username) {
    const regex = /^[a-z0-9]+$/;
    return regex.test(username);
}

export function validatePassword(password) {
    // Al menos: un número, una minúscula, una mayúscula y seis caracteres
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{6,}$/;
    return regex.test(String(password));
}

export function encode(value) {
    return btoa(btoa(btoa(value)));
}

export function decode(value) {
    return atob(atob(atob(value)));
}

export function formatRut(rut) {
    if (rut) {
        return rut.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    }
    return '';
}

export function unformatRut(rut) {
    return rut ? rut.replace(/\./g, '') : '';
}

export function validateRut(rutValue) {
    const rut = unformatRut(rutValue);
    const rexp = new RegExp(/^([0-9])+-([kK0-9])+$/);
    if (rut.match(rexp)) {
        const RUT = rut.split('-');
        const elRut = RUT[0].split('');
        let factor = 2;
        let suma = 0;
        let dv;
        for (let i = (elRut.length - 1); i >= 0; i -= 1) {
            factor = factor > 7 ? 2 : factor;
            suma += parseInt(elRut[i]) * parseInt(factor++);
        }
        dv = 11 - (suma % 11);
        if (dv === 11) {
            dv = 0;
        } else if (dv === 10) {
            dv = 'k';
        }

        if (dv.toString() === RUT[1].toLowerCase()) {
            return true;
        }
        return false;
    }
    return false;
}

export function formatPhone(phone) {
    if (phone) {
        return phone.replace(/[.-]/g, '').replace(/^(\d{3})(\d{4})(\d{4})$/, '($1) $2 $3');
    }
    return '';
}

export function unformatPhone(phone) {
    return phone ? phone.replace(/[^0-9.]/g, '') : '';
}

export function validateEmailList(emails) {
    const array = emails.split(',');
    for (let i = 0; i < array.length; i += 1) {
        const email = array[i].trim();
        if (!validateEmail(email)) {
            return false;
        }
    }
    return true;
}

export function dateToLocale(value) {
    const [date, time] = value.split(' ');
    let [year, month, day] = date.split('-');
    let [hh, mm, ss] = time.split(':');
    const d = new Date(Date.UTC(year, month - 1, day, hh, mm, ss));

    year = d.getFullYear();

    month = d.getMonth() + 1;
    month = (month < 10) ? (`0${month}`) : month;

    day = d.getDate();
    day = (day < 10) ? (`0${day}`) : day;

    hh = d.getHours();
    hh = (hh < 10) ? (`0${hh}`) : hh;

    mm = d.getMinutes();
    mm = (mm < 10) ? (`0${mm}`) : mm;

    ss = d.getSeconds();
    ss = (ss < 10) ? (`0${ss}`) : ss;

    return `${year}-${month}-${day} ${hh}:${mm}:${ss}`;
}

export function formatLogDate(dateStr) {
    const d = new Date(dateStr);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

export function formatDate(inputDate) {
    // Parsea la fecha en formato 'YYYY-MM-DD HH:mm:ss' a'DD-MM-YYYY'
    if(inputDate){
        const date = new Date(inputDate);
    
        const day = date.getDate().toString().padStart(2, '0'); 
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    }else{
        return inputDate;
    }    
  }

export const generateKey = (pre) => `${pre}_${Date.now()}`;
export function GetStatusInvolved(approver = null) {
    switch (approver) {
        case -2:
            return (
                <span className="badge badge-warning">
                    Pendiente por revisión
                </span>
            );
        case -1:
            return (
                <span className="badge badge-secondary">
                    Sin notificar
                </span>
            );
        case 0:
            return (
                <span className="badge badge-danger">
                    Rechazado
                </span>
            );
        case 1:
            return (
                <span className="badge badge-success">
                    Aprobado
                </span>
            );
        default:
            return (
                <span className="badge badge-info">
                    {approver}
                </span>
            );
    }
}
export function GetStatusContract(contractStatus = null) {
    switch (contractStatus) {
        case 'edition':
            return (
                <span className="badge badge-dark">
                    Edición
                </span>
            );
        case 'draft':
            return (
                <span className="badge badge-warning">
                    Borrador
                </span>
            );
        case 'approved':
            return (
                <span className="badge badge-success">
                    Aprobado
                </span>
            );
        case 'rejection':
            return (
                <span className="badge badge-danger">
                    Rechazado
                </span>
            );
        case 'revision':
        return(
            <span className="badge badge-primary">
                Revisión
            </span>
        );
        case 'signed':
        return(
            <span className="badge badge-success">
                Firmado
            </span>
        );
        case 'stopped':
        return(
            <span className="badge badge-danger">
                Detenido
            </span>
        );
        default:
            return (
                <span className="badge badge-secondary">
                    {contractStatus}
                </span>
            );
    }
}

export function GetStatusQuotas(contractStatus = null) {
    switch (contractStatus) {
        case 'paid':
            return (
                <span className="badge badge-success rounded-0 w-100 py-1 text-white">
                    Pagada
                </span>
            );
        case 'unpaid':
            return (
                <span className="badge badge-danger rounded-0 w-100 py-1 text-white">
                    No Pagada
                </span>
            );
        case 'issued':
            return (
                <span className="badge badge-primary rounded-0 w-100 py-1 text-white">
                    Emitida
                </span>
            );
        case 'notified':
            return (
                <span className="badge badge-info rounded-0 w-100 py-1 text-white">
                    Notificado
                </span>
            );
        case 'projected':
            return (
                <span className="badge badge-dark rounded-0 w-100 py-1 text-white">
                    Proyectada
                </span>
            );
        case 'not_found':
            return (
                <span className="badge badge-warning rounded-0 w-100 py-1 text-white">
                    No Encontrada
                </span>
            );
        case 'inactive':
            return (
                <span className="badge badge-secondary rounded-0 w-100 py-1 text-white">
                    Inactiva
                </span>
            );
        default:
            return (
                <span className="badge badge-secondary">
                    {`Estado:${contractStatus}`}
                </span>
            );
    }
}
export function Amount(value) {
    const formatter = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
    });
    return formatter.format(value);
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function findObject(o, s) {

    Object.byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }
    try {
        return Object.byString(o, s)
    } catch (error) {
        //console.error(error);
        return null
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
    }
    
}

